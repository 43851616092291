@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,700,700i,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700|Lobster+Two:400,700&display=swap');

html{
  overflow: hidden;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}


.md-selection-control-label {
  width: 100%;
}

.md-selection-control-label span {
  width: 100%;
}

/* 
.md-selection-control-toggle {
  width: 100%;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-family: 'Dosis', sans-serif !important;
  font-size: 18px !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Dosis', sans-serif;
  white-space: normal;
}

button {
  font-family: 'Dosis', sans-serif !important;
}



.flexBoxColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexBoxRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Dosis', sans-serif;
}

div, p{
  font-family: 'Dosis', sans-serif;
}


#root {
  height: 100%;
}