@import url('https://fonts.googleapis.com/css?family=Mr+De+Haviland&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

html,
body {
  width: 100%;
  height: 100%;
  font-family: Montserrat;
}


h1, h2,h3 ,h4 ,h5, h6 {
  font-family: Montserrat;
}

/* =========================================== General ==========================================================*/
p {
  margin: 0px;
}

.ff_wallpaper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.main-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
 z-index: 99;
}

.img-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
}

img {
   /*background-repeat: no-repeat;*/ 
   background-size: cover;
}

.main-view {
  /* height: 100%;
  display: inline-block; */
  width: 100%;
  /* overflow: auto; */
}

.content-view {
  height:100%;
  width: 70%;
  /* margin-left: 10%; */
  display: inline-block;
}

.left{
  height: calc(100vh + 56px);
  width: 10%;
  float: left;
}

.news-view {
  height: calc(100vh + 56px);
  width: 20%;
  float: right;
  right: 30px;
  
}




#wrapper {
  width: 100%;
  height: 100%;
  margin-top: 56px;
}

#footer {
  width: 100%;
  background-color: #343a40;
  display: inline-block;
  color: white;
}

.center {
  align-items: middle;
  justify-content: space-around;
  display: flex  !important;
}

.centerVert {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;

}

.side{
text-align: center;
}







.test{
    background-color: red !important;
}