.contentArea {
    height: 100% ;
    overflow: hidden;
}

.mob {
      display: none !important;
  }

  /* .desk {
    display: block !important;

} */


@media  (max-width: 650px) {
    .contentArea {
        
    }
}