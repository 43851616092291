.sidebar {
    height: calc(100% - 80px) ;
    width: 100%; 
  }

  .mob {
      display: none !important;
  }

  /* .desk {
    display: block !important;

} */


@media  (max-width: 650px) {
    /* Most of the smartphones */
    .sidebar {
        /* float: right; */
        /* height: 100%; */
        /* margin-top: 20px; */
        /* margin-bottom: 20px; */
      }

      .desk {
          display: none !important;
      }
  }