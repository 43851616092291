.contentArea {
    display: flex !important;
    padding:20px 100px 20px 300px;
    overflow: auto;
}

.contentContainer {
    width: 70%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Dosis', serif ;
}


@media  (max-width: 767px) {
    .contentArea {
        height: calc(100% - 150px);
        padding:10px 10px 10px 10px;
    }
    .contentContainer {
        width: 100%;
    }
}